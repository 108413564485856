<template>
    <div class="msp-misurazioni" 
        :class="classMisurazioni"
        @click="toggleMisurazioni"
        v-show="misurazioniConValore.length">
        <div class="items">
            <template v-for = "item in filteredMisurazioni" >
                <msp-misurazione-item
                    v-if="item"
                    :key = "'misurazione-'+item.parametro+'-'+item.data"
                    :misurazione="item"
                    :parametri="parametri"
                >
                </msp-misurazione-item>
            </template>
            </div>
            <div class="buttons" v-if="hasMore">
                <v-icon>{{iconToggle}}</v-icon>
            </div>
        </div>

</template>

<script>
import MspMisurazioneItem from "../components/MspMisurazioneItem.vue";
const sortByPosizione = function (parametri) {
    return function(a,b) {
        const parametroA = parametri.find(el=>el.codice==a.parametro);
        const parametroB = parametri.find(el=>el.codice==b.parametro);
        return (parametroA.posizione > parametroB.posizione) ? 1 : -1;
    }
}
void(sortByPosizione);

export default {
    components: {
        MspMisurazioneItem,
 },
  mixins: [
  ],

  props: [
      'misurazioni',
      'parametri'
  ],

  computed: {
      classMisurazioni: function () {
          const a_class=[];
          if (this.hasMore) {
              a_class.push('has-more');
          }
          if (this.active) {
              a_class.push('active');
          }
          return a_class.join(" ");
      },
      iconToggle: function () {
          return this.active ? 'mdi-close' : 'mdi-dots-horizontal';
      },
      hasMisurazioni: function () {
          return this.misurazioni && this.misurazioni.length;
      },
      hasMore: function () {
          return this.misurazioniConValore && this.misurazioniConValore.length > 1;
      },
      misurazioniConValore: function () {
          if (!this.hasMisurazioni) return [];
          const sorted = [...this.misurazioni].filter(el=>el.valori);
          //sorted.sort(sortByPosizione(this.parametri));
          return sorted;
      },
      filteredMisurazioni: function () {
          let filtered;
          const sorted = [...this.misurazioniConValore];
          if (!this.active) {
              filtered = [sorted[0]];
          } else {
              filtered = sorted;
          } 

          return filtered;
      },
  },

  data: function () {
    return {
      active: false
    }
  },
  watch: {

  },
  mounted: function() {

  },
  methods: {
      toggleMisurazioni: function () {
          if (!this.hasMore) return;
          this.active = !this.active;
      },
  }

}
</script>

<style lang="scss">
.msp-misurazioni {
    display: block;
    background: #fff;
    &.has-more {
        cursor: pointer;
    }
    &.active {
        .buttons {
            display: none;
        }
    }
    .buttons {
        text-align: right;
        .v-icon:before {
            line-height: 0;
        }
    }
    .items {
        padding: 5px 5px;
    }
}

</style>
