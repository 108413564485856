<template>
    <div class="msp-valore" v-if="valore" >
        <span v-if="label"><span v-html="label"></span>: </span>{{renderValue}} <span v-if="suffix" v-text='suffix'></span>
    </div>
</template>

<script>

export default {
  mixins: [
  ],

  props: [
      'misurazione',
      'parametro',
      'valore',
      'column',
  ],

  computed: {
      suffix: function () {
          if (!this.params) return null;
          const label = this.params.label;
          if (!label) return null;
          return label;
      },
      params: function () {
          if (!this.parametro) return null;
          if (!this.misurazione) return null;
          if (!this.column) return null;
          const params = this.parametro.valori[this.column][1];
          return params;
      },
      renderValue: function () {
          if (!this.misurazione) return null;
          const aspect = this.params && this.params.aspect;
          if (!aspect) return this.valore;
          if (aspect === 'percentage') {
              let perc = Math.round(this.valore * 100 / this.params.max);
              return perc + "%";
          }
          if (aspect === 'label') {
              const option = this.params.options.find(el=>el.value==this.valore);
              if (!option) return this.valore;
              return option.label;
          }
          if (aspect === 'true') {
              const label = this.params.true;
              return label;
          }
          return this.valore;
      },

      label: function() {
          if (!this.parametro) return "";
          const hideLabel = this.params && this.params.hide_label;
          if (hideLabel) return null;
          if (this.parametro.label == 'icon-battery') {
              return this.iconBattery(this.valore);
          } else if (this.parametro.label.indexOf('icon-') != -1) {
              return `<i class='msp-${this.parametro.label}'></i>`
          }
          return this.parametro.label; 
      }
  },

  data: function () {
    return {
      active: false
    }
  },
  watch: {

  },
  mounted: function() {

  },
  methods: {
      iconBattery: function (valore) {
          const ranges = [
              0,
              20,
              40,
              60,
              80,
              100,
          ];
          const nRanges = ranges.length;
          let range = 0;
          for (let i=0;i<nRanges;i++) {
              if (valore <= ranges[i]) {
                  range = ranges[i];
                  break;
              }
          }
          return `<i class='msp-icon-battery-${range}'></i>`
      },
  }

}
</script>

<style lang="scss">
.msp-misurazione-item {
}

</style>
